html {
    scroll-behavior: smooth;
}

body {
    background-color: #441089;
}

.underline {
    height: 2px;
    background-image: linear-gradient(
        90deg,
        #1ab7f2 0%,
        rgba(26, 183, 242, 0) 100%
    );
    bottom: -1px;
}

.topline {
    height: 2px;
    background-image: linear-gradient(
        90deg,
        rgba(26, 183, 242, 0) 0%,
        #1ab7f2 53.13%,
        rgba(26, 183, 242, 0) 100%
    );
}

.home-wrapper {
    background: linear-gradient(180deg, #431088 90%, #6127af 100%);
}

.about-wrapper {
    background: linear-gradient(180deg, #2e0a6f 40%, #20095e 100%);
}

.rain {
    position: relative;
    background: linear-gradient(177deg, #250a78 40%, #3a16a3 100%);
    overflow: hidden;
}

.rain:before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    content: '';
    background-image: url(../../images/rain.svg);
    background-repeat: repeat-x;
    background-size: auto 280px;
    animation-name: animatedRainBg;
    animation-duration: 10s;
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    z-index: 0;
}

@keyframes animatedRainBg {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    90% {
        opacity: 0.5;
    }
    99% {
        opacity: 0.2;
    }
    100% {
        transform: translateY(0px);
        opacity: 0;
    }
}
