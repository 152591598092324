.map {
    background-size: 100% 234px;
}

.point {
    background-color: #ff0ebb;
    box-shadow: 0px 0px 30px #ff0ebb;
    animation: animatedPoint 1s cubic-bezier(0.22, 0.61, 0.36, 1) infinite;
}

.point-top {
    top: -50px;
}

.point-bottom {
    top: -50px;
}

.text-desc {
    width: 200px;
}

@media (min-width: 1024px) {
    .map {
        background-image: url(../../../images/line.svg);
    }

    .point-top {
        top: 10%;
    }

    .point-bottom {
        top: auto;
        bottom: 13%;
    }
}

@media (min-width: 1124px) {
    .point-top {
        top: 6%;
    }

    .point-bottom {
        bottom: 8%;
    }
}

@media (min-width: 1250px) {
    .point-top {
        top: 4%;
    }

    .point-bottom {
        bottom: 6%;
    }
}

@media (min-width: 1324px) {
    .point-top {
        top: 3%;
    }

    .point-bottom {
        bottom: 4%;
    }
}

@media (min-width: 1366px) {
    .point-top {
        top: -1%;
    }

    .point-bottom {
        bottom: 0%;
    }
}

@media (min-width: 1440px) {
    .point-top {
        top: -4%;
    }

    .point-bottom {
        bottom: 0%;
    }
}

@media (min-width: 1536px) {
    .point-top {
        top: -2%;
    }

    .point-bottom {
        bottom: -2%;
    }
}

@keyframes animatedPoint {
    from {
        box-shadow: 0px 0px 0px #ff0ebb;
    }
    to {
        box-shadow: 0px 0px 40px #ff0ebb;
    }
}
